import React, { useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css"; // Import the CSS for styling
import logo from "../images/bosse.png";
import { FormContext } from "../FormContext"; // Import the context

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear(); // Assuming you store user data in localStorage
    navigate("/login"); // Redirect to login page after logout
  };
  return (
    <header className="form-header">
      <div className="logo-section">
        <img src={logo} alt="Bosse Logo" className="logo" />
      </div>

      <div className="header-right">
        <button className="header-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
